import React from 'react'
import { Prompt } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { Box, Divider, Grid } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { FoldingPanel, TextInput } from '../../../../../ui'
import { ThemeButton } from '../../../../../ui'
import { APPLICATION_STATES } from '../../../constants'
import {
  CANCELLED_STATES,
  NOT_ALLOWED_CANCELLATION_STATES,
  APP_CLOSED_STATES,
  CAN_CHANGE_STATE_ROLES,
} from './info-panel.constants'
import { RollbackPanel } from '../../rollback-panel'
import { CancelAppBtn } from '../../buttons'
import { SendToZeusCheckbox } from './send-to-zeus-checkbox.component'
import { useAppContext } from '../../../contexts'
import { ValidateApplication } from '../../validate-application'
import { ValidateCupsContratable } from '../../validate-cups-contratable'
import { ValidateDevolution } from '../../validate-devolucion'
import { useInfoPanel } from './info-panel.hook'
import { GRID_ITEM } from './info-panel.constants'
import { AppStateChangerBtn } from './app-state-changer-btn.component'
import { InfoBudgetExt } from './info-budget-ext.component'
import { InfoBudgetInt } from './info-budget-int.component'

export const InfoPanel = () => {
  const { formatMessage: fm } = useIntl()
  const { infoPanelFields, errorMssg, saveForm, hasFormChanged } = useInfoPanel()
  const { application, disabledForUser, loading } = useAppContext()
  const role = useSelector((state) => state.global.role)
  const defaultDisabled = disabledForUser()
  const isExterior = application.camino_solicitud === '01'
  const isCancellationNotAvailable =
    NOT_ALLOWED_CANCELLATION_STATES[application['estado']] ||
    (application.pendiente_anulacion &&
      application.estado === APPLICATION_STATES.PENDING_VALIDATION.KEY)

  const canSendToZEUS =
    (role === 'gestor' || role === 'delegado' || role === 'e_commerce') &&
    !defaultDisabled &&
    application.scr_enviable &&
    application.estado === APPLICATION_STATES.CREATED.KEY

  const handleSave = (e) => {
    e.preventDefault()
    saveForm()
  }

  const canShowCancelAppBtn = !isCancellationNotAvailable && !['gestor_gc'].includes(role)

  return (
    <FoldingPanel isExpanded title={fm({ id: 'pages.application.detail.info.title' })}>
      <Prompt
        when={hasFormChanged}
        message="Hay cambios sin Guardar, ¿Seguro que quieres abandonar la página?"
      />
      <form onSubmit={handleSave}>
        {!APP_CLOSED_STATES[application.estado] && !defaultDisabled ? (
          <Box display="flex" alignItems="center" pt={1} pb={1} flexWrap="wrap">
            <Box display="flex" flex={1} flexDirection="column-reverse">
              {application.estado === APPLICATION_STATES.PENDING_VALIDATION.KEY &&
              !application.validada_gestor &&
              !application.pasar_contratable &&
              !application.pendiente_anulacion ? (
                <Box mr={1} mt={2}>
                  <ValidateApplication />
                </Box>
              ) : application.estado === APPLICATION_STATES.PENDING_VALIDATION.KEY &&
                application.pasar_contratable &&
                !application.cod_nap ? (
                <Box mr={1} mt={2}>
                  <ValidateCupsContratable />
                </Box>
              ) : null}
              {application.pendiente_anulacion &&
              application.estado === APPLICATION_STATES.PENDING_VALIDATION.KEY ? (
                <Box mr={1} mb={2} mt={1}>
                  <ValidateDevolution />
                </Box>
              ) : null}
              {application.estado === APPLICATION_STATES.REVIEW.KEY ? (
                <Box mr={1} mb={2}>
                  <RollbackPanel />
                </Box>
              ) : null}

              {canShowCancelAppBtn ? <CancelAppBtn /> : null}

              {/*{application['estado'] === APPLICATION_STATES.PAYMENT_VALIDATION.KEY ? (*/}
              {/*  <>*/}
              {/*    <Box mr={1} ml={isCancellationAvailable ? 1 : 0}>*/}
              {/*      <ValidatePaymentBtn {...{ application, fetchApplication: getApplication }} />*/}
              {/*    </Box>*/}
              {/*    <InvalidatePaymentBtn {...{ application, fetchApplication: getApplication }} />*/}
              {/*  </>*/}
              {/*) : null}*/}
            </Box>
            {CAN_CHANGE_STATE_ROLES[role] ? (
              <Box ml={1}>
                <AppStateChangerBtn />
              </Box>
            ) : null}
            {!['gestor_gc'].includes(role) && (
            <Box ml={1}>
              <ThemeButton size="small" type="submit" disabled={!hasFormChanged} loading={loading}>
                {fm({ id: 'global.save' })}
              </ThemeButton>
            </Box>
            )}
          </Box>
        ) : null}

        <Box mb={1}>{errorMssg ? <Alert severity="error">{errorMssg}</Alert> : null}</Box>
        {isExterior ? (
          <InfoBudgetExt fields={infoPanelFields} />
        ) : (
          <InfoBudgetInt fields={infoPanelFields} />
        )}
        <Divider light style={{ margin: '20px 0' }} />
        <Grid container spacing={3}>
          <Grid {...GRID_ITEM.LARGE}>
            <TextInput {...infoPanelFields.observaciones_cliente} />
          </Grid>
          <Grid {...GRID_ITEM.LARGE}>
            <TextInput {...infoPanelFields.observaciones} />
          </Grid>
          <Grid {...GRID_ITEM.LARGE}>
            <TextInput {...infoPanelFields.observaciones_gestor} />
          </Grid>
          <Grid {...GRID_ITEM.LARGE}>
            <TextInput {...infoPanelFields.observaciones_cambio_estado} />
          </Grid>
          {CANCELLED_STATES[application.estado] ? (
            <>
              <Grid {...GRID_ITEM.LARGE}>
                <TextInput {...infoPanelFields.motivo_anulacion_cliente} />
              </Grid>
              <Grid {...GRID_ITEM.LARGE}>
                <TextInput {...infoPanelFields.motivo_anulacion_gestor} />
              </Grid>
            </>
          ) : null}
          {canSendToZEUS ? (
            <Grid {...GRID_ITEM.FULL}>
              <Divider light style={{ margin: '20px 0' }} />
              <SendToZeusCheckbox />
            </Grid>
          ) : null}
        </Grid>
      </form>
    </FoldingPanel>
  )
}
