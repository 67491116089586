import {
  TextInput,
  CheckListInput,
  DateInput,
  NumberInput,
  SelectInput,
  RadioInput,
} from '../../../../ui'
import { LocSelectInput } from '../custom-inputs'

const GRID_ITEM_FULL = {
  item: true,
  xs: 12,
  sm: 12,
  md: 12,
}

const GRID_ITEM_LARGE = {
  item: true,
  xs: 12,
  sm: 6,
  md: 6,
}

const mock_origen = {
  data: [
    { key: 'instalador', value: 'Instalador', id: '1' },
    { key: 'gremio', value: 'Asociación', id: '2' },
  ],
}

const computeDate = (date, days) => {
  if (!date) return
  const daysValue = parseInt(days || '0')
  const result = new Date(date)
  result.setDate(result.getDate() + daysValue)
  return result
}

export const INPUTS_CONFIG = ({ data, combos, intl, onChange, readOnly }) => {
  const { formatMessage } = intl
  const txt = (extension) =>
    formatMessage({ id: 'pages.campaigns.detail.fields.' + extension + '.title' })
  const DEFAULT_PROPS = { onChange, readOnly, fontSize: 'small', required: true }
  return {
    NOMBRE: {
      ...DEFAULT_PROPS,
      component: TextInput,
      value: data['nombre'],
      title: txt('nombre'),
      name: 'nombre',
    },
    CODIGO: {
      ...DEFAULT_PROPS,
      component: NumberInput,
      value: data['codigo'],
      title: txt('codigo'),
      name: 'codigo',
      required: true,
    },
    DESCUENTO: {
      ...DEFAULT_PROPS,
      component: NumberInput,
      value: data['descuento'],
      title: txt('descuento.public.offer'),
      name: 'descuento',
      units: '€',
      required: false,
    },
    INGRESO: {
      ...DEFAULT_PROPS,
      component: NumberInput,
      value: data['ingreso'],
      title: txt('ingreso'),
      name: 'ingreso',
      units: '€',
      required: false,
    },
    FINANCIACION: {
      ...DEFAULT_PROPS,
      component: SelectInput,
      // fullWidth: true,
      value: data['financiacion'],
      values: combos['binaryValues']?.data,
      title: txt('financiacion.public.offer'),
      name: 'financiacion',
      required: true,
    },
    MESES_FINANCIACION: {
      ...DEFAULT_PROPS,
      component: NumberInput,
      value: data['meses_financiacion'],
      title: txt('meses_financiacion'),
      name: 'meses_financiacion',
      required: data['financiacion'] === '01',
      hidden: data['financiacion'] === '00',
    },
    LIMITE_FINANCIACION: {
      ...DEFAULT_PROPS,
      component: NumberInput,
      value: data['limite_financiacion'],
      title: txt('limite_financiacion'),
      name: 'limite_financiacion',
      units: '€',
      required: data['financiacion'] === '01',
      hidden: data['financiacion'] === '00',
    },
    NOTIFICACION_INSTALADOR: {
      ...DEFAULT_PROPS,
      component: SelectInput,
      value: data['enviar_email']?.toString(),
      values: combos['booleanValues']?.data,
      title: txt('enviar_email'),
      name: 'enviar_email',
      required: true,
    },
    ELEMENTOS: {
      ...DEFAULT_PROPS,
      component: CheckListInput,
      value: data['elementos'],
      values: combos['productos']?.data,
      title: txt('elementos'),
      name: 'elementos',
      cKeyType: 'int',
      keyField: 'id',
      valueField: 'nombre',
      required: !data['mercados']?.some(
        (mercado) =>
          mercado.nombre === 'Pyme' ||
          mercado.nombre === 'PYME' ||
          mercado.nombre === 'Gran Consumo' ||
          mercado.nombre === 'GC' ||
          mercado.nombre === 'Caldera Centralizada' ||
          mercado.nombre === 'CC'
      ),
      hidden: data['mercados']?.some(
        (mercado) =>
          mercado.nombre === 'Pyme' ||
          mercado.nombre === 'PYME' ||
          mercado.nombre === 'Gran Consumo' ||
          mercado.nombre === 'GC' ||
          mercado.nombre === 'Caldera Centralizada' ||
          mercado.nombre === 'CC'
      ),
    },
    TARIFAS: {
      ...DEFAULT_PROPS,
      component: CheckListInput,
      value: data['tarifas'],
      values: combos['tarifa']?.data,
      title: txt('tariffs'),
      name: 'tarifas',
      cKeyType: 'string',
      keyField: 'id',
      valueField: 'nombre',
      required: data['mercados']?.some(
        (mercado) =>
          mercado.nombre === 'Pyme' ||
          mercado.nombre === 'PYME' ||
          mercado.nombre === 'Gran Consumo' ||
          mercado.nombre === 'GC' ||
          mercado.nombre === 'Caldera Centralizada' ||
          mercado.nombre === 'CC'
      ),
      hidden: !data['mercados']?.some(
        (mercado) =>
          mercado.nombre === 'Pyme' ||
          mercado.nombre === 'PYME' ||
          mercado.nombre === 'Gran Consumo' ||
          mercado.nombre === 'GC' ||
          mercado.nombre === 'Caldera Centralizada' ||
          mercado.nombre === 'CC'
      ),
    },
    MERCADOS: {
      ...DEFAULT_PROPS,
      component: CheckListInput,
      value: data['mercados'],
      values: combos['tipo_mercado_campañas']?.data,
      title: txt('mercados'),
      name: 'mercados',
      cKeyField: 'id',
      cKeyType: 'int',
      keyField: 'id',
      valueField: 'nombre',
    },
    FECHA_PUBLICACION: {
      ...DEFAULT_PROPS,
      component: DateInput,
      value: data['fechapublicacion'],
      title: txt('fechapublicacion'),
      name: 'fechapublicacion',
      maxDate: computeDate(data['fechafinvigencia'], data['tiempograbacion']),
    },
    TIEMPO_GRABACION: {
      ...DEFAULT_PROPS,
      component: NumberInput,
      value: data['tiempograbacion'],
      title: txt('tiempograbacion'),
      name: 'tiempograbacion',
      decimalScale: 0,
      units: 'días',
      required: false,
    },
    FECHA_FIN_VIGENCIA: {
      ...DEFAULT_PROPS,
      component: DateInput,
      value: data['fechafinvigencia'],
      title: txt('fechafinvigencia'),
      name: 'fechafinvigencia',
      minDate: data['fechainicio'],
    },
    FECHA_INICIO: {
      ...DEFAULT_PROPS,
      component: DateInput,
      value: data['fechainicio'],
      title: txt('fechainicio'),
      name: 'fechainicio',
      maxDate: data['fechafin'],
    },
    FECHA_FIN: {
      ...DEFAULT_PROPS,
      component: DateInput,
      value: computeDate(data['fechafinvigencia'], data['tiempograbacion']),
      title: txt('fechafin'),
      name: 'fechafin',
      minDate: data['fechainicio'],
      readOnly: true,
    },
    ORIGEN: {
      ...DEFAULT_PROPS,
      component: RadioInput,
      value: data['origen'],
      values: mock_origen?.data,
      title: txt('origen'),
      name: 'origen',
      required: true,
    },
    TIPO_GAS: {
      ...DEFAULT_PROPS,
      component: RadioInput,
      value: data['tipo_gas'],
      values: combos['tipo_gas_campana']?.data,
      title: txt('tipo_gas'),
      name: 'tipo_gas',
      required: true,
    },
    // OP_CHECK: {
    //   ...DEFAULT_PROPS,
    //   component: CheckboxComponent,
    //   value: data['is_op'],
    //   title: txt('is_op'),
    //   label: txt('is_op_label'),
    //   name: 'is_op',
    //   required: false,
    //   hidden: data['origen'] === 'cliente' /*true*/,
    // },
    COMUNIDADES: {
      ...DEFAULT_PROPS,
      component: LocSelectInput,
      value: data['comunidades'] || [],
      ccaaValues: combos['cod_zona_zeus']?.data,
      provValues: combos['provincias']?.data,
      guildValues: data.origen === 'gremio' ? combos['gremios']?.data : [],
      formState: data,
      name: 'comunidades',
      required: true,
    },
  }
}

export const FORM_CONFIG = [
  {
    fields: [
      { ref: 'NOMBRE', conf: GRID_ITEM_LARGE },
      { ref: 'CODIGO', conf: GRID_ITEM_LARGE },
    ],
    divider: true,
  },
  {
    title: 'Oferta económica',
    fields: [
      { ref: 'DESCUENTO', conf: GRID_ITEM_LARGE },
      // { ref: 'INGRESO', conf: GRID_ITEM_LARGE },
      // { ref: 'FINANCIACION', conf: GRID_ITEM_FULL },
      // { ref: 'MESES_FINANCIACION', conf: GRID_ITEM_LARGE },
      // { ref: 'LIMITE_FINANCIACION', conf: GRID_ITEM_LARGE },
    ],
    divider: true,
  },
  {
    title: 'Notificación instalador',
    description: 'El envío de la comunicación coincide con la fecha de publicación.',
    fields: [{ ref: 'NOTIFICACION_INSTALADOR', conf: GRID_ITEM_LARGE }],
    divider: true,
  },
  {
    title: 'Mercados y productos',
    fields: [
      { ref: 'MERCADOS', conf: GRID_ITEM_LARGE },
      { ref: 'ELEMENTOS', conf: GRID_ITEM_LARGE },
      { ref: 'TARIFAS', conf: GRID_ITEM_LARGE },
    ],
    divider: true,
  },
  {
    title: 'Tipo de gas',
    fields: [
      { ref: 'TIPO_GAS', conf: GRID_ITEM_LARGE },
    ],
    divider: true,
  },
  {
    title: 'Duración de la Oferta Pública',
    fields: [
      { ref: 'FECHA_INICIO', conf: GRID_ITEM_LARGE },
      { ref: 'FECHA_PUBLICACION', conf: GRID_ITEM_LARGE },
      { ref: 'FECHA_FIN_VIGENCIA', conf: GRID_ITEM_LARGE },
      { ref: 'TIEMPO_GRABACION', conf: GRID_ITEM_LARGE },
      { ref: 'FECHA_FIN', conf: GRID_ITEM_LARGE },
    ],
    divider: true,
  },
  {
    title: 'Origen de la Oferta Pública',
    fields: [
      { ref: 'ORIGEN', conf: GRID_ITEM_LARGE },
      // { ref: 'OP_CHECK', conf: GRID_ITEM_LARGE },
    ],
    divider: true,
  },
  {
    title: 'Zona de actuación',
    fields: [{ ref: 'COMUNIDADES', conf: GRID_ITEM_FULL }],
  },
]
